import * as localDb from "../../localDb";
import randomizeWithinRadius from "./randomizeWithinRadius";
import randomizePointsV3 from "./randomizePointsV3";
import * as turf from "@turf/turf";

export default async function getRandomPoint(monitoringSite, randomizeVersion, setMonitoringSite) {
  const samplingArea = await localDb.getOne("samplingAreas", monitoringSite.samplingAreaId);
  let result = null;
  let newPoint = null;
  let polygon = null;
  let randomizeCounter = monitoringSite.randomizeCounter;
  console.log(randomizeVersion);

  switch (randomizeVersion) {
    case "2.0.0":
      result = await randomizeWithinRadius({ monitoringSite, samplingArea });
      console.log(result);
      newPoint = result.newPoint;
      polygon = result.polygon;
      randomizeCounter = randomizeCounter + 1;
      break;
    case "3.0.0":
      [result] = randomizePointsV3({ monitoringSite, samplingArea });
      const coords = turf.getCoord(result);
      newPoint = { lat: coords[1], lng: coords[0] };
      polygon = null;
      randomizeCounter = result.properties.randomizeCounter;
      break;
    default:
      return randomizeWithinRadius({ monitoringSite, samplingArea });
  }
  setMonitoringSite({
    ...monitoringSite,
    randomizeCounter: randomizeCounter + 1,
    backupLocation: [newPoint.lat, newPoint.lng],
  });
  return { newPoint, polygon };
}
