import { Box, Divider, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TaskContext from "../../../context/taskContext.js";
import CoordinatesInputField from "../../CoordinatesInputField/CoordinatesInputField.jsx";
import NumberInputStepper from "../../NumberInputStepper/NumberInputStepper.jsx";
import { PhotoInput } from "../../PhotoInput/PhotoInput.jsx";
import * as localDb from "../../../services/localDb/index.js";

const fields = [
  {
    label: "Estructura de suelo",
    name: "fieldStructure",
    min: -20,
    max: 20,
    default: 0,
    enabled: false,
  },
];

const getPreviousTotalIse = async (activityId) => {
  const task = await localDb
    .getMany("tasks")
    .then((result) =>
      result
        .where({ monitoringActivityId: activityId, formName: "StmIseForm" })
        .toArray()
    );

  return task.length ? task[0].dataPayload?.totalIse : 0;
};

export default function TaskForm({
  watchPosition,
  stopWatch,
  editMode,
  plannedLocation,
  position,
  geolocation,
  handleCameraInput,
  pictures,
}) {
  const { task } = useContext(TaskContext);
  const { control, watch, setValue } = useFormContext();
  const [ehi, setEhi] = useState(0);
  const iseValues = watch(fields.map((f) => f.name));

  useEffect(() => {
    const calculateTotalIse = async () => {
      const previousIse = await getPreviousTotalIse(task.monitoringActivityId);
      const totalIse = iseValues.reduce((acc, value) => {
        acc += value || 0;
        return acc;
      }, 0);
      setValue("totalIse", totalIse);
      setEhi(totalIse + previousIse);
    };
    calculateTotalIse();
  }, [iseValues, setValue, task.monitoringActivityId]);

  console.log({ task });

  useEffect(() => {
    if (!editMode) {
      fields.forEach((field) => {
        setValue(field.name, field.default);
        setValue(`${field.name}-check`, field.enabled);
      });
    }
  }, [editMode, setValue]);

  return (
    <Stack width={"100%"}>
      <CoordinatesInputField
        control={control}
        editMode={editMode}
        setValue={setValue}
        position={position}
        plannedLocation={plannedLocation}
        watch={watchPosition}
        stopWatch={stopWatch}
        geolocation={geolocation}
      />
      <Controller
        control={control}
        name="totalIse"
        render={(field, fieldSate) => (
          <Stack
            backgroundColor={"white"}
            position="sticky"
            top={40}
            zIndex={999}
            pb={3}
          >
            <Typography alignSelf="center" variant="h1" fontWeight={600}>
              {ehi}
            </Typography>
            <Typography alignSelf="center" variant="caption">
              PUNTAJE TOTAL
            </Typography>
          </Stack>
        )}
      />
      <Divider />

      {fields?.map((fieldConfig, index) => {
        return (
          <Stack width={"100%"} spacing={1} pt={1} key={index}>
            <Stack
              width={"100%"}
              flex
              flexGrow={1}
              direction="row"
              alignItems="start"
              justifyContent="space-between"
              spacing={1}
              key={index}
            >
              <Stack
                width={"50%"}
                m={0}
                direction="row"
                alignItems={"center"}
                spacing={0.5}
              >
                <Controller
                  control={control}
                  name={`${fieldConfig.name}-check`}
                  defaultValue={fieldConfig.enabled}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      sx={{ pl: 0 }}
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                        if (value === false) {
                          setValue(fieldConfig.name, 0);
                        }
                      }}
                    />
                  )}
                />
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "break-spaces",
                  }}
                  component="div"
                  variant="caption"
                >
                  {fieldConfig.label.toUpperCase()}{" "}
                </Typography>
              </Stack>
              <Box>
                <Controller
                  control={control}
                  name={fieldConfig.name}
                  rules={{ disabled: !watch(`${fieldConfig.name}-check`) }}
                  defaultValue={fieldConfig.default}
                  render={({ field }) => (
                    <Stack direction="column" spacing={1} alignItems="center">
                      <NumberInputStepper
                        {...field}
                        disabled={!watch(`${fieldConfig.name}-check`)}
                        readOnly={true}
                        min={fieldConfig.min}
                        max={fieldConfig.max}
                        name={fieldConfig.name}
                        step={
                          fieldConfig.min === -20 || fieldConfig.max === 20
                            ? 10
                            : 5
                        }
                        onChange={(evt, value) => {
                          evt.preventDefault();
                          evt.stopPropagation();
                          field.onChange(value);
                        }}
                      />
                      <Typography variant="caption">
                        {fieldConfig.min}/{fieldConfig.max}
                      </Typography>
                    </Stack>
                  )}
                />
              </Box>
            </Stack>
            <Divider />
          </Stack>
        );
      })}
      <Stack
        alignItems={"center"}
        flex
        width={"100%"}
        sx={{ marginTop: "20px", width: "100%" }}
      >
        <Controller
          key="comments"
          control={control}
          name="comments"
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={fieldState.error ? true : false}
              helperText={fieldState.error?.message}
              type="text"
              multiline
              rows={5}
              sx={{ width: "100%" }}
              label="Observaciones"
              InputProps={{
                type: "text",
              }}
            />
          )}
        />
      </Stack>
      <Stack alignItems={"center"} spacing={1} sx={{ marginTop: "20px" }}>
        <Typography>Fotografía del sitio</Typography>
        <PhotoInput
          width="150"
          height="150"
          handleCameraFile={handleCameraInput}
          picture={pictures.find((pic) => pic.key === task.key)}
          pictureKey={
            pictures.find((pic) => pic.key === task.key)?.key || task.key
          }
          pictureIndex={
            pictures.findIndex((pic) => pic.key === task.key) >= 0
              ? pictures.findIndex((pic) => pic.key === task.key)
              : 0
          }
          pictureId={pictures.find((pic) => pic.key === task.key)?.id || null}
        />
      </Stack>
    </Stack>
  );
}
